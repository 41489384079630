@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ensure consistent white background */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #4A4A4A; /* Replace with your preferred dark gray color */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-dots {
    background-image: radial-gradient(circle, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
    background-size: 20px 20px;
}



h2, p, button {
    font-family: 'Lato', sans-serif;
}

.nature-bg {
    background-color: #3a5a40;
}

    .nature-bg:hover {
        background-color: #A3B18A; /* Hover background color */
    }

.bordered-frame {
    position: relative;
    border: 6px solid #3a5a40;  
    border-radius: 0rem;
    overflow: hidden;
    padding: 1.5rem;
}

    .bordered-frame::before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border: 2px solid #3a5a40;
        pointer-events: none; /* Allow click events to pass through */
    }

/* For different screen sizes, you might want to adjust the padding */
@media (min-width: 640px) {
    .bordered-frame {
        padding: 1rem; /* More space for larger screens */
    }
}

@media (min-width: 768px) {
    .bordered-frame {
        padding: 3.5rem; /* Even more space for larger screens */
    }
}

@media (min-width: 768px) {
    .bordered-frame {
        padding: 1rem;
    }
}