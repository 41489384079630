/* General container styles */
.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}

@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

/* Body and navigation styles */
body, html, nav ul {
    font-family: 'Poppins', sans-serif; /* Apply font consistently */
    margin: 0;
    padding: 0;
    /*background-color: #212427;*/ /* Charcoal gray background */
}

/* Header specific styles */
header {
    background-color: transparent; /* Transparent header */
    margin: 0;
    padding: 0;
}

/* Main content padding */
main {
    padding-top: 4rem;
}

@media (max-width: 640px) {
    main {
        padding-top: 3rem;
    }
}